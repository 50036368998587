<template>
  <div class="row">
    <div class="col-md-12">
      <h4 class="title">Inventory Manage</h4>
    </div>
    <div class="col-md-12 card">
      <div class="card-body row">
        <div class="col-sm-1">
          <p-button class="no-margin" type="default" block outline round  @click="openAddToStockModal()"> <i class="fa fa-cart-plus"></i> </p-button>
        </div>
        <div class="col-sm-1">
          <p-button class="no-margin" type="default" block outline round  @click="openTransferStockModal()"> <i class="fa fa-shopping-cart"></i> </p-button>
        </div>
        <div class="col-sm-1">
          <p-button class="no-margin" type="default" block outline round  @click="openReturnStockModal()"> <i class="fa fa-undo"></i> </p-button>
        </div>
        <div class="col-sm-3"  v-if="pagination">
          <el-select
            class="select-default"
            v-model="pagination.per_page"
            placeholder="Per page"
            @change="changePerPage()"
          >
            <el-option
              class="select-default"
              v-for="item in perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <fg-input
              class="input-sm"
              placeholder="Search"
              v-model="searchQuery"
              addon-right-icon="nc-icon nc-zoom-split"
            ></fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table
            class="table-striped"
            :data="tableData"
            border
            style="width: 100%;"
            height="600"
          >
            <el-table-column
              v-for="(column) in tableColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
              :sortable="column.sortable || false"
              :align="column.align"
              :header-align="column.align"
              v-if="!column.hidden"
              :fixed="column.fixed || false"
            ></el-table-column>

            <el-table-column :min-width="150" fixed="right" class-name="td-actions" label="Actions">
              <template slot-scope="props">
                <!-- <p-button type="info" size="sm" icon @click="handleLike(props.$index, props.row)">
                  <i class="fa fa-eye"></i>
                </p-button> -->
                <p-button
                  type="success"
                  size="sm"
                  icon
                  @click="handleEdit(props.$index, props.row)"
                >
                  <i class="fa fa-edit"></i>
                </p-button>
                <p-button
                  type="danger"
                  size="sm"
                  icon
                  @click="handleDelete(props.$index, props.row)"
                >
                  <i class="fa fa-trash"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info"  v-if="pagination">
          <p
            class="category"
          >Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries</p>
        </div>
        <div class="col-sm-6"  v-if="pagination">
          <p-pagination
            class="pull-right"
            v-model="pagination.current_page"
            :per-page="Number.parseInt(pagination.per_page)"
            :total="pagination.total"
            @input="changePage()"
          ></p-pagination>
        </div>
      </div>
    </div>

    <modal
      :show.sync="modals.addStock"
      headerClasses="justify-content-center"
      footer-classes="justify-content-center"
      >
      <h5 slot="header" class="modal-title">Manage Stock</h5>
      <form data-vv-scope="add">
        <div class="row">
          <div class="form-group col-12">
            <label for>Location</label>
            <fg-input
              name="location_id"
              v-model="location_id"
              v-validate="modelValidations.location_id"
              :error="getError('location_id')"
            >
              <el-select
                class="select-default"
                name="location_id"
                placeholder="Select Location"
                v-model="model.location_id"
                filterable
                default-first-option
                multiple
                collapse-tags
              >
                <el-option
                  v-for="(option, index) in locations"
                  class="select-success"
                  :value="option.id"
                  :label="`${option.location_id} - ${option.name}`"
                  :key="index"
                ></el-option>
              </el-select>
            </fg-input>
          </div>

          <div class="form-group col-12">
            <label for>Product</label>
            <fg-input
              name="product_id"
              v-model="product_id"
              v-validate="modelValidations.product_id"
              :error="getError('product_id')"
            >
              <el-select
                name="product_id"
                :class="!getError('product_id') ? 'select-default' : 'select-danger'"
                class="select-default"
                placeholder="Select Product"
                v-model="model.product_id"
                filterable
                default-first-option
                multiple
                collapse-tags
              >
                <el-option
                  v-for="(option, index) in products"
                  class="select-success"
                  :value="option.id"
                  :label="`${option.product_id} - ${option.name}`"
                  :key="index"
                ></el-option>
              </el-select>
            </fg-input>
          </div>

          <div class="form-group col-12">
            <label for>Quantity</label>
            <el-slider :min="0" :max="1000" v-model="model.quantity" show-input></el-slider>
          </div>
        </div>
      </form>
      <template slot="footer">
        <p-button type="success" @click="validate('add')">Update Stock</p-button>
        <!-- <p-button type="danger" @click.native="modals.addStock = false">Close</p-button> -->
      </template>
    </modal>

     <modal
      :show.sync="modals.transferStock"
      headerClasses="justify-content-center"
      footer-classes="justify-content-center"
      >
      <h5 slot="header" class="modal-title">Transfer Stock</h5>
      <form data-vv-scope="transfer">
        <div class="row">
          <div class="form-group col-6">
            <label for>From Location</label>
            <fg-input
              name="from_id"
              v-model="model.transfer.from_id"
              v-validate="modelValidations.transfer.from_id"
              :error="getError('from_id')"
            >
              <el-select
                name="from_id"
                :class="!getError('from_id') ? 'select-default' : 'select-danger'"
                placeholder="Select From Location"
                v-model="model.transfer.from_id"
                filterable
              >
                <el-option
                  v-for="(option, index) in locations"
                  class="select-success"
                  :value="option.id"
                  :label="`${option.location_id} - ${option.name}`"
                  :key="index"
                ></el-option>
              </el-select>
            </fg-input>
          </div>

          <div class="form-group col-6">
            <label for>To Location</label>
            <fg-input
              name="to_id"
              v-model="model.transfer.to_id"
              v-validate="modelValidations.transfer.to_id"
              :error="getError('to_id')"
            >
              <el-select
                name="to_id"
                :class="!getError('from_id') ? 'select-default' : 'select-danger'"
                placeholder="Select To Location"
                v-model="model.transfer.to_id"
                filterable
              >
                <el-option
                  v-for="(option, index) in locations"
                  class="select-success"
                  :value="option.id"
                  :label="`${option.location_id} - ${option.name}`"
                  :key="index"
                ></el-option>
              </el-select>
            </fg-input>
          </div>

          <div class="form-group col-6">
            <label for>Product</label>
            <fg-input
              name="product_id"
              v-model="model.transfer.product_id"
              v-validate="modelValidations.transfer.product_id"
              :error="getError('product_id')"
            >
              <el-select
                name="product_id"
                :class="!getError('product_id') ? 'select-default' : 'select-danger'"
                class="select-default"
                placeholder="Select Product"
                v-model="model.transfer.product_id"
                filterable
              >
                <el-option
                  v-for="(option, index) in products"
                  class="select-success"
                  :value="option.id"
                  :label="`${option.product_id} - ${option.name}`"
                  :key="index"
                ></el-option>
              </el-select>
            </fg-input>
          </div>

          <div class="form-group col-4">
            <label>Transfer Date</label>
            <fg-input
              name="date"
              type="datetime"
              v-validate="modelValidations.transfer.date"
              v-model="model.transfer.date"
              :error="getError('date')"
            >
              <el-date-picker
                name="date"
                v-model="model.transfer.date"
                type="date"
                placeholder="Pick a Date"
              ></el-date-picker>
            </fg-input>
          </div>

          <div class="form-group col-12">
            <label for>Quantity</label>
            <el-slider :min="0" :max="1000" v-model="model.transfer.quantity" show-input></el-slider>
          </div>

          <div class="form-group col-12">
            <label>Transfer Notes</label>
            <fg-input
              type="text"
              name="note"
              v-validate="modelValidations.transfer.note"
              :error="getError('note')"
              v-model="model.transfer.note"
            ></fg-input>
          </div>

          
        </div>
      </form>
      <template slot="footer">
        <p-button type="success" @click="validate('transfer')">Transfer Stock</p-button>
        <!-- <p-button type="danger" @click.native="modals.addStock = false">Close</p-button> -->
      </template>
    </modal>

    <modal
      :show.sync="modals.returnStock"
      headerClasses="justify-content-center"
      footer-classes="justify-content-center"
      >
      <h5 slot="header" class="modal-title">Return Stock</h5>
      <form data-vv-scope="add">
        <div class="row">
          <div class="form-group col-12">
            <label for>Location</label>
            <fg-input
              name="location_id"
              v-model="location_id"
              v-validate="modelValidations.location_id"
              :error="getError('location_id')"
            >
              <el-select
                name="location_id"
                :class="!getError('location_id') ? 'select-default' : 'select-danger'"
                placeholder="Select Location"
                v-model="model.location_id"
                filterable
                multiple
                default-first-option
                collapse-tags
              >
                <el-option
                  v-for="(option, index) in locations"
                  class="select-success"
                  :value="option.id"
                  :label="`${option.location_id} - ${option.name}`"
                  :key="index"
                ></el-option>
              </el-select>
            </fg-input>
          </div>

          <div class="form-group col-12">
            <label for>Product</label>
            <fg-input
              name="product_id"
              v-model="product_id"
              v-validate="modelValidations.product_id"
              :error="getError('product_id')"
            >
              <el-select
                name="product_id"
                :class="!getError('product_id') ? 'select-default' : 'select-danger'"
                class="select-default"
                placeholder="Select Product"
                v-model="model.product_id"
                filterable
                default-first-option
                multiple
                collapse-tags
              >
                <el-option
                  v-for="(option, index) in products"
                  class="select-success"
                  :value="option.id"
                  :label="`${option.product_id} - ${option.name}`"
                  :key="index"
                ></el-option>
              </el-select>
            </fg-input>
          </div>

          <div class="form-group col-12">
            <label for>Quantity</label>
            <el-slider :min="0" :max="1000" v-model="model.quantity" show-input></el-slider>
          </div>
        </div>
      </form>
      <template slot="footer">
        <p-button type="success" @click="validate('return')">Update Stock</p-button>
        <!-- <p-button type="danger" @click.native="modals.addStock = false">Close</p-button> -->
      </template>
    </modal>

  </div>
</template>


<script>
import Vue from "vue";
import { Modal } from "src/components/UIComponents";
import { Table, TableColumn, Select, Option, Slider, DatePicker } from "element-ui";
import PPagination from "src/components/UIComponents/Pagination.vue";
// import users from "../Tables/users.js";
import swal from 'sweetalert2'
import { mapGetters } from "vuex";

Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);
Vue.use(Slider);

export default {
  components: {
    [DatePicker.name]: DatePicker,
    PPagination,
    Modal
  },
  mounted() {
    this.$store.dispatch("fetchInventories", { per_page: 10, page: 1 });
    this.$store.dispatch("fetchLocationsAll");
    this.$store.dispatch("fetchProductsAll");
  },
  watch: {
      searchQuery: function (val) {
          this.$store.dispatch("fetchInventories", {
              searchQuery: this.searchQuery,
              per_page: this.pagination.per_page,
              page: this.pagination.current_page
          }); 
      }
  },
  computed: {
    ...mapGetters({
      tableData: "getInventories",
      pagination: "getPagination",
      locations: "getLocations",
      products: "getProducts"
    }),
    location_id(){
      if(this.model.location_id.length)
      return this.model.location_id.join(',')
      else
      return ''
    },
    product_id(){
      if(this.model.product_id.length)
      return this.model.product_id.join(',')
      else
      return ''
    }
  },
  data() {
    return {
      modals: {
        addStock: false,
        returnStock: false,
        transferStock: false
      },
      perPageOptions: ["10", "30", "50", "100", "250", "500"],
      searchQuery: "",
      propsToSearch: [
        "product_id",
        "product_name",
        "location_id",
        "location_name",
        "quantity"
      ],
      tableColumns: [
        {
          prop: "id",
          label: "ID",
          minWidth: 70,
          hidden: true,
          align: "center"
        },
        {
          prop: "l_id",
          label: "LID",
          minWidth: 70,
          hidden: false,
          align: "center"
        },
        {
          prop: "p_id",
          label: "PID",
          minWidth: 70,
          hidden: false,
          align: "center"
        },
        {
          prop: "location_id",
          label: "LOC ID",
          minWidth: 150,
          fixed: false,
          sortable: true,
          align: "center"
        },
        {
          prop: "location_name",
          label: "Location Name",
          minWidth: 250,
          sortable: true,
          align: "left"
        },
        {
          prop: "product_id",
          label: "Prod ID",
          minWidth: 150,
          fixed: false,
          sortable: true,
          align: "center"
        },
        {
          prop: "product_name",
          label: "Product Name",
          minWidth: 250,
          sortable: true,
          align: "left"
        },
        {
          prop: "quantity",
          label: "Quantity",
          minWidth: 150,
          align: "center"
        }
      ],
      model: {
        location_id: [],
        product_id: [],
        quantity: 0,
        transfer: {
          from_id: null,
          to_id: null,
          product_id: null,
          quantity: 0,
          date: null,
          note: ''
        }
      },
      modelValidations: {
        location_id: {
          required: true
        },
        product_id: {
          required: true
        },
        quantity: {
          required: true
        },
        transfer: {
          from_id: {
            required: true
          },
          to_id: {
            required: true
          },
          product_id: {
            required: true
          },
          quantity: {
            required: true
          },
          date: {
            required: true
          },
          note: {
            required: false
          }
        }
        
      }
      // tableData: this.locations
    };
  },
  methods: {
    changePerPage() {
      this.$store.dispatch("fetchInventories", {
        per_page: this.pagination.per_page,
        page: 1, searchQuery : this.searchQuery
      });
    },
    changePage() {
      this.$store.dispatch("fetchInventories", {
        per_page: this.pagination.per_page,
        page: this.pagination.current_page, searchQuery : this.searchQuery
      });
    },
    handleEdit(index, row) {
      // this.$router.push(`/products/edit/${row.id}`);
      this.model.location_id = [row.l_id];
      this.model.product_id = [row.p_id];
      this.model.quantity = row.quantity;
      this.modals.addStock = true;
      // this.$store.dispatch("createInventory", this.model);
    },
    handleDelete(index, row) {
      // this.$store.dispatch("deleteInventory", row.id);
      swal({
          title: "Are you sure?",
          text: `You won't be able to revert this!`,
          showCancelButton: true,
          confirmButtonClass: "btn btn-danger btn-fill",
          cancelButtonClass: "btn btn-default btn-fill",
          confirmButtonText: "Delete it!",
          buttonsStyling: false
      })
      .then(result => { this.$store.dispatch("deleteInventory", row.id); })
      .catch(cancel => { console.log(cancel) });
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate(scope) {
      switch(scope){
        case 'add' : 
          this.$validator.validateAll(scope).then(isValid => {
            if (isValid) {
              this.modals.addStock = false;
              this.$store.dispatch("createInventory", this.model);
            }
          });
          break;
        case 'return' : 
          this.$validator.validateAll(scope).then(isValid => {
            if (isValid) {
              this.modals.returnStock = false;
              this.$store.dispatch("returnInventory", this.model);
            }
          });
          break;
        case 'transfer' :
          this.$validator.validateAll(scope).then(isValid => {
            if (isValid) {
              this.modals.transferStock = false;
              let payload = {
                location_id_1: this.model.transfer.from_id,
                location_id_2: this.model.transfer.to_id,
                product_id: this.model.transfer.product_id,
                quantity: this.model.transfer.quantity,
                date: this.model.transfer.date,
                note: this.model.transfer.note
              }
              this.$store.dispatch("createTransfer", payload);
            }
          });
          break; 
      }
      
    },
    openAddToStockModal(){
      this.model.location_id = [];
      this.model.product_id = [];
      this.model.quantity = 0;
      this.modals.addStock = true;
    },
    openReturnStockModal(){
      this.model.location_id = [];
      this.model.product_id = [];
      this.model.quantity = 0;
      this.modals.returnStock = true;
    },
    openTransferStockModal(){
      this.model.transfer.from_id = null;
      this.model.transfer.to_id = null;
      this.model.transfer.product_id = null;
      this.model.transfer.quantity = 0;
      this.modals.transferStock = true;
    }
  }
};
</script>

<style lang="scss">
.el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }
}

button.no-margin{
  margin: 0;
}

.el-table,
.el-table tr,
.el-table thead th {
  background-color: white !important;
}

.form-group .el-input-number {
  width: 35%;
  outline: none;
}
.el-input--small .el-input__inner {
  border-radius: 50px;
}
</style>
